import { Camera } from "./camera";
import Layers from "./layers";
// import Settings from "./settings";
import Sound from "./sound";
import { global } from "./global";
import {
  BALL_R,
  GOAL_R,
  HEIGHT,
  MARGIN,
  MAX_X,
  MAX_Y,
  MIN_X,
  MIN_Y,
  WIDTH,
} from "./constants";

// const startingTimestamp = performance.now();
// const gracePeriod = 3000;

// Primary render function
export default function render(canvas, ctx, timestamp, delta) {
  // These are some special states where a render is always needed, beyond this
  // changes to Layers will always trigger a new render
  // if (window.clientMoved || window.clientPressed || window.clientReleased ||
  //   Camera.startT ||
  //   timestamp - startingTimestamp <= gracePeriod) {
  //   Game.renderNeeded = true;
  // }
  Sound.update(timestamp);
  global.player.update(timestamp, delta);
  global.opponent.update(timestamp, delta);
  global.ball.update(timestamp, delta);
  // if (!Game.renderNeeded) {
  //   return;
  // }
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  Camera.frame(ctx, timestamp, delta);

  // Render bg lines
  ctx.strokeStyle = "#fff";
  ctx.lineWidth = 3;
  ctx.beginPath();
  ctx.roundRect(
    MIN_X + MARGIN,
    MIN_Y + MARGIN,
    WIDTH - 2 * MARGIN,
    HEIGHT - 2 * MARGIN,
    BALL_R
  );
  ctx.moveTo(MIN_X + MARGIN * 0.5, 0);
  ctx.lineTo(MAX_X - MARGIN * 0.5, 0);
  ctx.moveTo(GOAL_R, MIN_Y);
  ctx.arc(0, MIN_Y, GOAL_R, 0, Math.PI);
  ctx.moveTo(GOAL_R, MAX_Y);
  ctx.arc(0, MAX_Y, GOAL_R, 0, Math.PI, true);
  ctx.stroke();

  // Render dynamic content
  Layers.render(ctx, timestamp);

  // Game.renderNeeded = false;
  ctx.setTransform(1, 0, 0, 1, 0, 0);

  // // TEMP: Gamepad debug
  // if (window.clientGamepad) {
  //   ctx.fillStyle = 'white';
  //   ctx.textBaseline = 'top';
  //   ctx.textAlign = 'left';
  //   ctx.font = '18px monospace';
  //   ctx.fillText(window.clientGamepad.debugString, 8, 8);
  // }
}
