export const SQRT_3 = Math.sqrt(3);
export const HALF_SQRT_3 = SQRT_3 / 2;

export const CAMERA_X = 0.5;
export const CAMERA_Y = 0.5;

export const WIDTH = 400;
export const HEIGHT = 712;

export const MAX_X = WIDTH * 0.5;
export const MIN_X = -MAX_X;

export const MAX_Y = HEIGHT * 0.5;
export const MIN_Y = -MAX_Y;
export const MARGIN = 4;

export const BALL_R = 20;
export const INK_LENGTH = 150;
export const GOAL_R = 100;

export const BASE_SPEED = 0.5;

export const COLOR = Object.freeze({
  // background: "#058338",
  background: "oklch(49.35% 0.1492 154.28)",
  playerBackground: "#0e172a",
  opponentBackground: "#260404",
  player: "#61dafb",
  opponent: "#ff9900",
  neutral: "#ffffff",
  hand: "#ffffff",
  invalid: "#ff6363",
  a: "#2beb54",
  b: "#ff300e",
  x: "#2787e9",
  y: "#ffffff",
  black: "black",
  white: "#ffffff",
  green: "#73d13d",
  blue: "#1890ff",
  orange: "#fa541c",
  gold: "#fadb14",
});

export const CSS_VARS = Object.freeze(
  Object.fromEntries(Object.entries(COLOR).map(([k, v]) => [`--${k}-color`, v]))
);

export const FONT = Object.freeze({
  CELL_SYMBOL: '900 21px "Font Awesome 6 Pro"',
  CELL_COST_ICON: (size) => `900 ${size}px "Font Awesome 6 Pro"`,
  CELL_COST_TEXT: (size) => `${size}px "Bungee", Roboto, Helvetica, sans-serif`,
});
