import { Ball } from "./ball";
import { OpponentController } from "./opponent";
import { PlayerController } from "./player";

class GlobalState {
  constructor() {
    this.ball = new Ball();
    this.player = new PlayerController();
    this.opponent = new OpponentController();
    this.ink = [this.player.ink, this.opponent.ink];
  }
}

export const global = new GlobalState();
