import React, { useEffect, useRef, useState } from "react";
import render from "./render";
import { global } from "./global.js";
import { COLOR, CSS_VARS, HEIGHT, WIDTH } from "./constants";
import { updatePatterns, IMAGES } from "./pattern";
import { Camera } from "./camera";
import Layers from "./layers";
import Settings from "./settings";
import Sound from "./sound";
import { niceAwakening, rudeAwakening, sleep, snooze } from "./util";
import { Player } from "./player.js";
// import Save from "./save";

function startGame() {
  Layers.removeAll();
  // TODO
}

const index = (n) => ({
  "--index": n,
});

const PATTERN_VARS = Object.freeze({
  "--lines-pattern": IMAGES.lines,
  "--waves-pattern": IMAGES.waves,
});

// const temporaryStates = new Set(["pause", "settings"]);

export default function Canvas(props) {
  // const [mounted, setMounted] = useState(false);
  const [state, setState] = useState("in-game");
  global.setState = setState;
  const [settings, setSettings] = useState(Settings.json);

  const canvasEl = useRef(null);
  const background = COLOR.background;
  const [cursor, setCursor] = useState("");
  const [size, setSize] = useState(Camera.style);
  const [transform, setTransform] = useState("");
  const DPI = window.devicePixelRatio || 1;

  useEffect(() => {
    // Resize
    const onResize = () => {
      setSize(Camera.calculateScale());
      // Game.renderNeeded = true;
    };
    onResize();
    Camera.setTransform = setTransform;
    window.addEventListener("resize", onResize);

    // Fullscreen
    const onFullscreenChange = () => {
      setSettings(Settings.json);
    };
    document.addEventListener("fullscreenchange", onFullscreenChange);

    // // Load state
    // Hand.recover().then(() => {
    //   // setState(Save.state);
    //   // Save.gc();
    //   setMounted(true);
    // });

    // TEMP: Testing
    // setState("upgrade");

    return () => {
      window.removeEventListener("resize", onResize);
      document.removeEventListener("fullscreenchange", onFullscreenChange);
    };
  }, []);

  useEffect(() => {
    global.state = state;
  }, [state]);
  // useEffect(() => (Game.background = background), [background]);

  useEffect(() => {
    const $canvas = canvasEl.current;
    if (!$canvas) {
      return;
    }

    // Frames
    const ctx = $canvas.getContext("2d");
    updatePatterns(ctx);
    let requestId, previousTimestamp;
    const onFrame = (timestamp, firstPaint = false) => {
      requestId = window.requestAnimationFrame(onFrame);
      if (!previousTimestamp) {
        previousTimestamp = timestamp - 1;
      }

      // If the game is paused, don't render updates
      if (state !== "in-game") {
        Sound.update(timestamp);
        return;
      }

      // Handle pointer state
      if (window.clientHeld === true) {
        if (window.clientHeldPrev === false) {
          window.clientPressed = true;
          window.lastPressedT = Date.now();
        } else {
          window.clientPressed = false;
        }
      } else {
        window.clientPressed = false;
        if (window.clientHeldPrev === true) {
          window.clientReleased = true;
        } else {
          window.clientReleased = false;
        }
      }
      window.clientHeldPrev = window.clientHeld;

      // Handle actual pointer x/y
      if (window.clientMoved && window.lastInputType !== "gamepad") {
        window.clientX =
          ((window.clientRawX - Camera.leftOffset - window.innerWidth * 0.5) /
            Camera.scale) *
            DPI +
          WIDTH * 0.5 -
          Camera.x;
        window.clientY =
          ((window.clientRawY - Camera.topOffset - window.innerHeight * 0.5) /
            Camera.scale) *
            DPI +
          HEIGHT * 0.5 -
          Camera.y;
      }

      // Override actual pointer stuff when gamepad :)
      if (window.clientGamepad) {
        window.clientGamepad.update();

        if (window.lastInputType === "gamepad") {
          setCursor("none");
        } else {
          setCursor("");
        }
      }

      // Actually render
      render(
        $canvas,
        ctx,
        timestamp,
        timestamp - previousTimestamp,
        firstPaint
      );
      previousTimestamp = timestamp;
      window.clientMoved = false;
    };
    onFrame(performance.now(), true);
    return () => window.cancelAnimationFrame(requestId);
  }, [canvasEl, state, DPI]);

  const enterBiome = () => {
    setState("in-game");
    Sound.play("select");
    Camera.reset(0);
    // Game.reset();
    startGame();
    // Grid.animateIn();
  };

  const showSettings = () => {
    setState("settings");
    Sound.play("select");
  };

  const pauseGame = () => {
    snooze();
    setState("pause");
    Sound.play("select");
  };

  const resumeGame = () => {
    if (state === "settings") {
      setState("start");
    } else {
      niceAwakening();
      setState("in-game");
    }
    Sound.play("select");
  };

  const forfeitGame = () => {
    rudeAwakening();
    setState("start");
    Sound.play("select");
  };

  const toggleSetting = async (name) => {
    if (name !== "fullscreen") {
      Settings.set(name, !Settings[name]);
    }
    Sound.isMuted = !Settings.sound;
    if (!Sound.isMuted) {
      await Promise.race([sleep(250), Sound.ready]);
    }
    Sound.play("select");
    if (name === "fullscreen") {
      // Game.toggleFullscreen();
      return;
    }
    setSettings(Settings.json);
  };

  const settingNames = ["sound", "wobble"];
  if (document.fullscreenEnabled) {
    settingNames.unshift("fullscreen");
  }

  return (
    <div
      className="Game"
      style={{
        "--scale": Camera.scale / DPI,
        "--background": background,
        ...CSS_VARS,
        ...PATTERN_VARS,
        background,
        cursor,
      }}
    >
      <main
        className="frame"
        style={{
          // background,
          transform: state === "in-game" ? transform : null,
          ...size,
        }}
      >
        {(state === "in-game" ||
          state === "pause" ||
          state === "hexylpedia") && (
          <canvas
            ref={canvasEl}
            className={state === "in-game" ? "active" : "inactive"}
            width={Camera.width}
            height={Camera.height}
            style={{ ...size }}
          />
        )}
        {state === "start" && (
          <React.Fragment>
            {/* <section className='startPattern'></section> */}
            <nav>
              <button
                className="iconButton"
                onClick={showSettings}
                data-icon={"\uf013"}
              ></button>
              <a href="https://ian.dev" className="iconButton credits">
                <span className="icon" />
              </a>
            </nav>
            <header className="gameName"></header>
            <section className="biomeList">
              <div className="biome">
                <div className="visual">
                  <div className="solid-layer" />
                  <div className="pattern-layer" />
                  <div className="icon-layer" data-icon={"\uf7ab"} />
                </div>
                <header data-name="Ready?"></header>
              </div>
            </section>
            <section className="bottomActions">
              <button className="enterBiome" onClick={enterBiome}>
                Explore
              </button>
            </section>
          </React.Fragment>
        )}
        {/* state === "in-game" && (
          <nav>
            <button
              className="iconButton"
              onClick={pauseGame}
              data-icon={"\uf0c9"}
            ></button>
          </nav>
        ) */}
        {(state === "settings" || state === "pause") && (
          <section className={`${state}Menu`}>
            <div className="row">
              <button
                className="iconButton"
                onClick={resumeGame}
                data-icon={"\ue59b"}
              ></button>
              <span />
            </div>
            <section className="menuList">
              {state === "pause" ? (
                <React.Fragment>
                  <button onClick={resumeGame} style={index(1)}>
                    Resume
                  </button>
                  <button onClick={forfeitGame} style={index(2)}>
                    Abandon Run
                  </button>
                  <hr style={index(3)} />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <button onClick={resumeGame} style={index(1)}>
                    Back
                  </button>
                  <hr style={index(2)} />
                </React.Fragment>
              )}
              {settingNames.map((x, i) => (
                <button
                  key={x}
                  className={settings[x] ? "enabled" : "disabled"}
                  onClick={() => toggleSetting(x)}
                  style={index(4 + i)}
                >
                  {x}
                </button>
              ))}
            </section>
          </section>
        )}
      </main>
    </div>
  );
}
