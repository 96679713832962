// import {
//   angleDelta,
//   angleTo,
//   clamp,
//   distanceBetween,
//   inRect,
//   lineCollision,
//   range,
// } from "./util";
import { WIDTH, HEIGHT, MAX_X, MAX_Y, MIN_X, MIN_Y } from "./constants";
import { InkController } from "./ink";
import Layers, { PlayerStatusLayer } from "./layers";

export class PlayerController {
  constructor() {
    // Create the ink controller
    this.ink = new InkController();
    // Set initial layers
    this.setLayers();
  }

  // TODO: json for saving

  update(timestamp, delta) {
    this.ink.update(window.clientX, window.clientY, window.clientHeld);
  }

  damage(other) {
    if (this.combo < 8) {
      this.health -= other.health;
    }
    this.combo = 0;
  }

  setLayers() {
    Layers.remove(this);
    // Layers.add(PlayerStatusLayer, null, this);
  }
}
