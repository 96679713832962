import {
  BALL_R,
  GOAL_R,
  MARGIN,
  MAX_X,
  MAX_Y,
  MIN_X,
  MIN_Y,
} from "./constants";
import { global } from "./global";
import Layers, { BallLayer } from "./layers";
import { choose, distanceBetween, range } from "./util";

export class Ball {
  constructor(projection = false) {
    this.projection = projection;
    this.reset();
    this.setLayers();
  }

  reset() {
    this.x = 0;
    this.y = 0;
    this.a = choose([-1, 1]) * Math.PI * range(0.375, Math.PI * 0.625);
    this.speed = 0.25;
    this.dx = Math.cos(this.a) * this.speed;
    this.dy = Math.sin(this.a) * this.speed;
  }

  resetInk() {
    for (const ink of global.ink) {
      ink.reset();
    }
  }

  update(timestamp, delta) {
    // Move
    this.x += this.dx * delta;
    this.y += this.dy * delta;

    // Check for collisions with walls
    if (this.x - BALL_R < MIN_X + MARGIN) {
      this.dx = Math.abs(this.dx);
      this.x += this.dx * delta;
    } else if (this.x + BALL_R > MAX_X - MARGIN) {
      this.dx = -Math.abs(this.dx);
      this.x += this.dx * delta;
    }
    if (this.y - BALL_R < MIN_Y + MARGIN) {
      if (Math.abs(this.x) + BALL_R < GOAL_R) {
        // Wait until it's fully in the goal
        if (this.y + BALL_R < MIN_Y + MARGIN && !this.projection) {
          // TODO: player scored!
          this.reset();
          this.resetInk();
        }
      } else {
        this.dy = Math.abs(this.dy);
        this.y += this.dy * delta;
      }
    } else if (this.y + BALL_R > MAX_Y - MARGIN) {
      if (Math.abs(this.x) + BALL_R < GOAL_R) {
        // Wait until it's fully in the goal
        if (this.y - BALL_R > MAX_Y - MARGIN && !this.projection) {
          // TODO: opponent scored!
          this.reset();
          this.resetInk();
        }
      } else {
        this.dy = -Math.abs(this.dy);
        this.y += this.dy * delta;
      }
    }

    // Check for collisions with ink
    const ex = this.x - this.dx * delta * 0.5,
      ey = this.y - this.dy * delta * 0.5,
      er = BALL_R + this.speed * delta * 0.5;
    let px = 0,
      py = 0,
      pc = 0;
    for (const ink of global.ink) {
      for (const p of ink.points) {
        if (distanceBetween(ex, ey, p.x, p.y) <= er) {
          px += p.x;
          py += p.y;
          ++pc;
        }
      }
    }
    if (pc) {
      px /= pc;
      py /= pc;
      this.angle = Math.atan2(this.y - py, this.x - px);
      this.speed *= 1.1;
      this.dx = Math.cos(this.angle) * this.speed;
      this.dy = Math.sin(this.angle) * this.speed;
      this.x += this.dx * delta;
      this.y += this.dy * delta;
    }
  }

  /**
   * Projects this ball into the future on its current path
   * to see where it will be
   * @param {number} duration how far into the future to predict
   * @param {number} precision how often to emulate an update
   */
  project(duration = 200, precision = 50) {
    let timestamp = performance.now();
    for (let i = duration; i > 0; i -= precision) {
      timestamp += precision;
      this.update(timestamp, precision);
    }
  }

  /**
   * Create a copy of this Ball which can be safely projected
   */
  createProjection() {
    const ball = new Ball(true);
    ball.x = this.x;
    ball.y = this.y;
    ball.a = this.a;
    ball.speed = this.speed;
    ball.dx = this.dx;
    ball.dy = this.dy;
    return ball;
  }

  setLayers() {
    Layers.remove(this);
    if (this.projection) return;
    Layers.add(BallLayer, null, this);
  }
}
