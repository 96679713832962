export function irandom(n) {
  return Math.floor(n * Math.random());
}

export function irange(min, max) {
  return min + irandom(1 + max - min);
}

export function range(min, max) {
  return min + (max - min) * Math.random();
}

export function clamp(min, n, max) {
  return Math.max(min, Math.min(n, max));
}

export function choose(array) {
  return array[irandom(array.length)];
}

export function distanceBetween(x1, y1, x2, y2) {
  return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
}

export function angleTo(fromX, fromY, toX, toY) {
  return Math.atan2(toY - fromY, toX - fromX);
}

export function angleDelta(fromA, toA) {
  return ((toA - fromA + 3 * Math.PI) % (2 * Math.PI)) - Math.PI;
}

// for (const [from, to] of [
//   [0, 1],
//   [0, 2],
//   [1, 0],
//   [0.5, 0.25],
// ]) {
//   console.log(
//     "%fpi to %fpi is %fpi",
//     from,
//     to,
//     angleDelta(from * Math.PI, to * Math.PI) / Math.PI
//   );
// }

export function inRect(x, y, left, top, right, bottom) {
  return x >= left && x <= right && y >= top && y <= bottom;
}

export function circleCollision(x1, y1, r1, x2, y2, r2) {
  const distance = distanceBetween(x1, y1, x2, y2);
  return distance <= r1 + r2;
}

export function lineCollision(ax1, ay1, ax2, ay2, bx1, by1, bx2, by2) {
  const denominator = (ax2 - ax1) * (by2 - by1) - (ay2 - ay1) * (bx2 - bx1);
  const numerator1 = (ay1 - by1) * (bx2 - bx1) - (ax1 - bx1) * (by2 - by1);
  const numerator2 = (ay1 - by1) * (ax2 - ax1) - (ax1 - bx1) * (ay2 - ay1);

  // Detect coincident lines
  if (denominator === 0) {
    return numerator1 === 0 && numerator2 === 0;
  }

  const r = numerator1 / denominator;
  const s = numerator2 / denominator;

  return r >= 0 && r <= 1 && s >= 0 && s <= 1;
}

export function inLine(x, y, r, x1, y1, x2, y2) {
  const length = distanceBetween(x1, y1, x2, y2);
  const d1 = distanceBetween(x, y, x1, y1);
  const d2 = distanceBetween(x, y, x2, y2);

  console.log("|%d| vs %d", d1 + d2 - length, r);

  return Math.abs(d1 + d2 - length) <= r;
}

export function drawPolygon(ctx, x, y, r, a = 0, sides = 3) {
  const da = 2 * (1 / sides) * Math.PI;
  for (let i = sides + 1; i--; ) {
    const pa = a + i * da;
    const px = x + r * Math.cos(pa);
    const py = y + r * Math.sin(pa);
    if (i === sides) {
      ctx.moveTo(px, py);
      continue;
    }
    ctx.lineTo(px, py);
  }
}

const sleepSet = new Set();

export function sleep(n) {
  let ref;
  return new Promise((resolve, reject) => {
    sleepSet.add(
      (ref = {
        resolve,
        reject,
        timeout: setTimeout(resolve, n),
        timestamp: Date.now() + n,
        remaining: n,
      })
    );
  }).finally(() => sleepSet.delete(ref));
}

// Pause every single pending sleep Promise
export function snooze() {
  const now = Date.now();
  for (const ref of sleepSet) {
    if (!ref.timeout) {
      continue;
    }
    clearTimeout(ref.timeout);
    ref.timeout = 0;
    ref.remaining = ref.timestamp - now;
  }
}

// Resume every paused sleep Promise
export function niceAwakening() {
  const now = Date.now();
  for (const ref of sleepSet) {
    if (ref.timeout) {
      continue;
    }
    ref.timeout = setTimeout(ref.resolve, ref.remaining);
    ref.timestamp = now + ref.remaining;
    ref.remaining = 0;
  }
}

// Reject every single pending sleep Promise
export function rudeAwakening() {
  for (const { reject } of sleepSet) {
    reject();
  }
}

export function randomSleep(n, variation = Math.min(n * 0.5, 100)) {
  return sleep(n + irange(-variation, variation));
}

export function repeat(x, n) {
  return new Array(n).fill(x);
}

export function shuffle(array) {
  for (let i = 10; i--; ) {
    array.sort(() => (Math.random() < 0.5 ? -1 : 1));
  }
  return array;
}

export function allIn(expecting, ...values) {
  return values.every((x) => expecting.includes(x));
}
