// import {
//   angleDelta,
//   angleTo,
//   clamp,
//   distanceBetween,
//   inRect,
//   lineCollision,
//   range,
// } from "./util";
import { Ball } from "./ball";
import { WIDTH, HEIGHT, MAX_X, MAX_Y, MIN_X, MIN_Y, BALL_R } from "./constants";
import { global } from "./global";
import { InkController } from "./ink";
import { angleTo, distanceBetween, irandom, irange } from "./util";

export class OpponentController {
  constructor() {
    // Create the ink controller
    this.ink = new InkController(-1);
    this.animation = null;
    this.reactionDelay = 300;
    this.dangerT = 0;
  }

  update(timestamp, delta) {
    if (this.animation) {
      const p = this.animation.points[this.animation.nextIndex];
      if (!p) {
        this.ink.update(0, 0, false);
        this.animation = null;
        return;
      }
      this.ink.update(p.x, p.y, true);
      if (timestamp >= p.t) {
        ++this.animation.nextIndex;
      }
      return;
    }

    if (!this.dangerT && global.ball.dy < 0) {
      this.dangerT = timestamp;
    }
    if (timestamp - this.dangerT >= this.reactionDelay) {
      const duration = 100;
      const whoopsie = irange(0, Math.pow(10, 1 + 2 * global.ball.speed));
      const ball = global.ball.createProjection();
      ball.project(duration + 200);

      // if the ball won't be a problem, just wait
      if (ball.y >= -100 || ball.dy > 0) {
        this.ink.update(0, 0, false);
        return;
      }

      const gx = ball.x + irange(-50, 50);
      const gy = ball.y + irange(-80, 0);

      const x1 = gx - irange(30, 100);
      const y1 = gy + irange(-30, 30);
      const x2 = gx + irange(30, 100);
      const y2 = gy + irange(-30, 30);
      const a = angleTo(x1, y1, x2, y2);
      const d = distanceBetween(x1, y1, x2, y2);
      const precision = 4;
      const count = Math.ceil(d / precision);
      const points = new Array(count);
      for (let i = count; i--; ) {
        const ratio = i / (count - 1);
        points[i] = {
          x: x1 + Math.cos(a) * d * ratio,
          y: y1 + Math.sin(a) * d * ratio,
          t: timestamp + ratio * (duration + whoopsie) + irange(-20, 20),
        };
      }

      for (let repeat = 3; repeat--; ) {
        const magnitude = 4;
        const falloff = 0.9;
        const i = irandom(count);
        let xOffset = irandom(-magnitude, magnitude);
        let yOffset = irandom(-magnitude, magnitude);
        for (
          let j = 0;
          i - j >= 0 || i + j < count;
          ++j, xOffset *= falloff, yOffset *= falloff
        ) {
          const left = points[i - j];
          const right = j !== 0 && points[i + j];
          if (left) {
            left.x += xOffset;
            left.y += yOffset;
          }
          if (right) {
            right.x += xOffset;
            right.y += yOffset;
          }
        }
      }

      this.animation = {
        duration: duration + whoopsie,
        points,
        nextIndex: 0,
      };
      this.dangerT = 0;
    }
    this.ink.update(0, 0, false);
  }
}
