window.clientX = 0;
window.clientY = 0;
window.clientRawX = 0;
window.clientRawY = 0;
window.clientMoved = false;
window.clientHeld = false;
window.clientHeldPrev = false;
window.clientPressed = false;
window.clientReleased = false;
window.clientGamepad = null;
window.lastPressedT = 0;
window.lastInputType = "mouse";
window.inTouch = false;

/// Listen for mouse events
window.addEventListener("mousedown", () => {
  window.clientHeld = true;
  if (!window.inTouch) {
    window.lastInputType = "mouse";
  }
});
window.addEventListener("mouseup", () => (window.clientHeld = false));

window.addEventListener("mousemove", (e) => {
  window.clientRawX = e.clientX;
  window.clientRawY = e.clientY;
  window.clientMoved = true;
  if (window.lastInputType === "gamepad") {
    window.lastInputType = "mouse";
  }
});

/// Listen for touch events
window.addEventListener(
  "touchstart",
  (e) => {
    // if (e.target.closest('button')) {
    // 	return;
    // }
    // e.preventDefault();
    window.clientHeld = true;
    window.clientMoved = true;
    window.clientRawX = e.touches[0].clientX;
    window.clientRawY = e.touches[0].clientY;
    window.lastInputType = "touch";
    window.inTouch = true;
  },
  { passive: false }
);
window.addEventListener("touchend", (e) => {
  if (e.touches.length !== 0) {
    return;
  }
  window.clientHeld = false;
  window.inTouch = 0.5;
  setTimeout(() => {
    if (window.inTouch !== 0.5) {
      return;
    }
    window.lastInputType = "touch";
    window.inTouch = false;
  });
});

window.addEventListener(
  "touchmove",
  (e) => {
    e.preventDefault();
    window.clientMoved = true;
    window.clientRawX = e.touches[0].clientX;
    window.clientRawY = e.touches[0].clientY;
    window.lastInputType = "touch";
  },
  { passive: false }
);

class ClientGamepad {
  constructor(index) {
    // Index used to get the current gamepad state from navigator
    this.index = index;
    // Controls that are being polled for this game
    this.left = new ClientGamepadJoystick(0, 1);
    this.a = new ClientGamepadButton(0);
    this.b = new ClientGamepadButton(1);
    this.x = new ClientGamepadButton(2);
    this.y = new ClientGamepadButton(3);
    // State that determines what can currently be done with a controller
    this.currentOption = [0, 0]; // The current [x, y] pair
    this.options = []; // Determines valid client [x, y] pairs
  }

  get gamepad() {
    return navigator.getGamepads()[this.index];
  }

  get debugString() {
    return `Gamepad(left: ${this.left.debugString}, a: ${this.a.debugString}, b: ${this.b.debugString}, x: ${this.x.debugString}, y: ${this.y.debugString})`;
  }

  poll() {
    const { gamepad } = this;
    if (!gamepad) {
      // TODO: reset to default state?
      // console.error(`Could not find gamepad#${this.index}`);
      return;
    }
    this.left.poll(gamepad);
    this.a.poll(gamepad);
    this.b.poll(gamepad);
    this.x.poll(gamepad);
    this.y.poll(gamepad);
  }

  update() {
    this.poll();
    if (this.options.length && (this.left.pressed || this.left.repeated)) {
      const c = this.currentOption;
      if (!this.options.some(([x, y]) => x === c[0] && y === c[1])) {
        this.currentOption = this.options[0].slice();
        window.clientMoved = true;
      } else {
        const hoverDistance = 64;
        const hoverDistance2 = hoverDistance ** 2;
        for (let distance = 32; distance < 800; distance += 8) {
          const dx = c[0] + distance * Math.cos(this.left.angle);
          const dy = c[1] + distance * Math.sin(this.left.angle);
          let closest = null,
            closestDistance = Infinity;
          for (const [x, y] of this.options) {
            if (x === c[0] && y === c[1]) {
              continue;
            }
            const d2 = (x - dx) ** 2 + (y - dy) ** 2;
            if (d2 < closestDistance) {
              closest = [x, y];
              closestDistance = d2;
            }
          }
          if (closestDistance <= hoverDistance2) {
            this.currentOption = closest;
            window.clientMoved = true;
            break;
          }
        }
      }
    }
    if (window.lastInputType === "gamepad") {
      // Impersonate a pointer (shh...)
      window.clientX = this.currentOption[0];
      window.clientY = this.currentOption[1];
      window.clientPressed = this.a.pressed;
      window.clientHeld = this.a.held;
      window.clientReleased = this.a.released;
    }
    // Handle button callbacks
    this.a.update();
    this.b.update();
    this.x.update();
    this.y.update();
  }

  vibrate(intensity = 1, duration = 100) {
    const { gamepad } = this;
    if (!gamepad) {
      return;
    }
    gamepad.hapticActuators?.[0]?.pulse(intensity, duration);
    gamepad.vibrationActuator?.playEffect("dual-rumble", {
      startDelay: 0,
      duration: duration,
      weakMagnitude: intensity,
      strongMagnitude: intensity,
    });
  }
}

class ClientGamepadJoystick {
  constructor(xIndex, yIndex) {
    this.xIndex = xIndex;
    this.yIndex = yIndex;
    this.x = 0;
    this.y = 0;
    this.distance = 0;
    this.angle = 0;
    this.pressed = false;
    this.held = false;
    this.released = false;
    // Interval repeat state (similar to keydown)
    this.repeated = false;
    this.repeatInterval = 125;
    this.repeatTimestamp = 0;
  }

  get state() {
    if (this.pressed) {
      return "pressed";
    } else if (this.repeated) {
      return "repeated";
    } else if (this.held) {
      return "held";
    } else if (this.released) {
      return "released";
    }
    return "inert";
  }

  get debugString() {
    return `${this.state}:[${this.x.toFixed(2)},${this.y.toFixed(2)}]`;
  }

  poll(gamepad) {
    this.x = gamepad.axes[this.xIndex];
    this.y = gamepad.axes[this.yIndex];
    this.distance = Math.sqrt(this.x ** 2 + this.y ** 2) * 0.5;
    this.angle = Math.atan2(this.y, this.x);
    const held = this.distance >= 0.3;
    if (held && !this.held) {
      this.pressed = true;
      this.held = true;
      this.repeatTimestamp = performance.now() + this.repeatInterval * 2.5;
      window.lastInputType = "gamepad";
    } else if (!held && this.held) {
      this.released = true;
      this.held = false;
    } else {
      if (this.pressed) {
        this.pressed = false;
      }
      if (this.repeated) {
        this.repeated = false;
      } else if (this.held && performance.now() >= this.repeatTimestamp) {
        this.repeated = true;
        this.repeatTimestamp += this.repeatInterval;
      }
      if (this.released) {
        this.released = false;
      }
    }
  }
}

class ClientGamepadButton {
  constructor(index) {
    this.index = index;
    this.pressed = false;
    this.held = false;
    this.released = false;
    this.callback = null;
    this.lastPress = 0;
  }

  get state() {
    if (this.pressed) {
      return "pressed";
    } else if (this.held) {
      return "held";
    } else if (this.released) {
      return "released";
    }
    return "inert";
  }

  get debugString() {
    return this.state;
  }

  poll(gamepad) {
    const held = gamepad.buttons[this.index].pressed;
    if (held && !this.held) {
      const now = Date.now();
      const delta = now - this.lastPress;
      // Incredibly rapid taps are probably a glitchy gampepad
      // source: my xbox controllers
      if (delta < 300) {
        return;
      }
      this.lastPress = now;
      this.pressed = true;
      this.held = true;
      window.lastInputType = "gamepad";
    } else if (!held && this.held) {
      this.released = true;
      this.held = false;
    } else {
      if (this.pressed) {
        this.pressed = false;
      }
      if (this.released) {
        this.released = false;
      }
    }
  }

  update() {
    if (this.pressed && this.callback) {
      this.callback();
    }
  }
}

window.addEventListener("gamepadconnected", (e) => {
  console.log(e);
  window.clientGamepad = new ClientGamepad(0);
  window.lastInputType = "gamepad";
  window.Grid?.updateGamepadOptions();
});

// TODO: listen for disconnect
