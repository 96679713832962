import { GOAL_R, HEIGHT, INK_LENGTH, MARGIN, MAX_X, MIN_X } from "./constants";
import Layers, { InkLayer } from "./layers";
import { distanceBetween } from "./util";

const THRESHHOLD = 2;

export class InkController {
  constructor(side = 1) {
    // How many times have we scored on the opponent
    this.score = 0;
    // Which points have we drawn on the field
    this.points = [];
    // Whether ink was being drawn in a valid location last frame
    this.wasDrawing = false;
    // Which side of the y-axis this ink should be on
    this.side = side;
    // Set initial layers
    this.setLayers();
  }

  add(x, y) {
    let d = 0,
      isConnected = false;
    if (this.wasDrawing) {
      isConnected = true;
      const previous = this.points.at(-1);
      d = Math.sqrt(Math.pow(previous.x - x, 2) + Math.pow(previous.y - y, 2));
      if (d <= THRESHHOLD) return;
    }
    this.wasDrawing = true;
    this.points.push({
      x,
      y,
      d,
      t: Date.now(),
      isConnected,
    });
    this.prune();
  }

  prune() {
    let d = 0;
    for (let i = this.points.length; i--; ) {
      const p = this.points[i];
      d += p.d;
      if (d >= INK_LENGTH) {
        this.points.splice(0, Math.max(0, i - 1));
        const from = this.points[0];
        if (from) {
          from.d = 0;
          from.isConnected = false;
        }
        if (from && from !== p) {
          const over = d - INK_LENGTH;
          const ratio = over / p.d;
          const a = Math.atan2(p.y - from.y, p.x - from.x);
          from.x += Math.cos(a) * ratio * p.d;
          from.y += Math.sin(a) * ratio * p.d;
          p.d -= over;
        } else {
          p.d = 0;
          p.isConnected = false;
        }
        break;
      }
    }
  }

  /**
   * Called when the user has stopped drawing in valid location
   */
  release() {
    if (!this.wasDrawing) return;
    this.wasDrawing = false;
    if (this.points.at(-1)?.isConnected === false) {
      this.points.pop();
    }
  }

  update(x, y, isHeld) {
    if (!isHeld) {
      this.release();
      return;
    }
    const isInvalidSide = Math.sign(this.side * y) !== 1;
    const isOutside = x < MIN_X + MARGIN || x > MAX_X - MARGIN;
    const goalY = this.side * HEIGHT * 0.5;
    const isInGoal = distanceBetween(x, y, 0, goalY) <= GOAL_R;
    if (isInvalidSide || isOutside || isInGoal) {
      this.release();
      return;
    }
    this.add(x, y);
  }

  reset() {
    this.points.splice(0);
    this.wasDrawing = false;
  }

  setLayers() {
    Layers.remove(this);
    // TODO: maybe sort into two layers based on fresh/stale?
    Layers.add(InkLayer, null, this);
  }
}
