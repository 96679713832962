import { BALL_R, COLOR, FONT, MAX_X, MAX_Y, MIN_X, MIN_Y } from "./constants";
// import { PATTERNS } from "./pattern";
import { angleTo, clamp, distanceBetween, drawPolygon, range } from "./util";

const Layers = new (class {
  constructor() {
    // Layers in the order they should be rendered to the canvas
    this.ordered = [];
    // .idString reference for every layer
    this.reference = {};
    // Keeps track of unique types that are currently being rendered
    this.types = new Set();
  }

  // Adds `value` to the layer with the given `type` and `id`, creating it
  // if necessary. (`removePrevious` indicates whether to remove this value
  // from other layers of the same type before adding it to this layer.)
  add(type, id, value, removePrevious = true) {
    if (type === "string") {
      throw new Error(`Layers.add expects a class for type, not a string.`);
    }
    if (removePrevious) {
      value?.$layers?.[type.type]?.remove(value);
    }
    const idString = this.getIdString(id);
    const key = `${type.type}:${idString}`;
    let layer = this.reference[key];
    if (!layer) {
      layer = this.reference[key] = new type(id, idString);
      layer.idString = idString;
      layer.key = key;
      this.types.add(layer.type);
      this.updateOrder();
    }
    layer.add(value);
    if (!value.$layers) {
      value.$layers = {};
    }
    value.$layers[type.type] = layer;
    return layer;
  }

  getIdString(id = {}) {
    if (!id || typeof id !== "object") {
      return id;
    }
    return Object.keys(id)
      .sort()
      .map((x) => `${x}=${id[x]}`)
      .join(":");
  }

  // Removes layers that don't have values
  prune() {
    this.types.clear();
    for (let i = this.ordered.length; i--; ) {
      const layer = this.ordered[i];
      if (layer.values.length) {
        this.types.add(layer.type);
        continue;
      }
      layer.values.splice(i, 1);
      delete this.reference[layer.key];
    }
  }

  // This removes the given value from any layer that contains it
  remove(value, type = null) {
    for (const layer of this.ordered) {
      if (type && layer instanceof type === false) {
        continue;
      }
      layer.remove(value);
    }
  }

  // Removes every single layer
  removeAll() {
    for (const layer of this.ordered) {
      for (const value of layer.values.slice()) {
        layer.remove(value);
      }
    }
    this.prune();
  }

  // Renders all layers in order
  render(ctx, timestamp) {
    for (const layer of this.ordered) {
      if (layer.values.length) {
        layer.render(ctx, timestamp);
      }
    }
  }

  // Updates layer order to ensure things are rendered properly
  updateOrder() {
    this.ordered = Object.values(this.reference).sort((a, b) => {
      if (a.priority !== b.priority) {
        return a.priority - b.priority;
      }
      // TODO
      return 0;
    });
  }
})();

export default Layers;

// Abstract class extended for specific layers of related rendering operations
class Layer {
  constructor(id) {
    if (!this.constructor.type) {
      throw new Error(`${this.constructor.name}.type is not defined!`);
    }
    this.type = this.constructor.type;
    this.priority = 0;
    this.id = id;
    this.idString = "";
    this.key = "";
    this.values = [];
    this.prepare();
  }

  get value() {
    return this.values[0];
  }

  add(value) {
    if (this.values.includes(value)) {
      return;
    }
    this.values.push(value);
  }

  prepare() {
    // Overridden by classes that extend this
  }

  remove(value) {
    const index = this.values.indexOf(value);
    if (index === -1) {
      return;
    }
    this.values.splice(index, 1);
    if (value.$layers[this.type] === this) {
      value.$layers[this.type] = null;
    }
  }

  render(ctx, timestamp) {
    throw new Error(`${this.constructor.name}.render is not implemented!`);
  }
}

export class PlayerPointsLayer extends Layer {
  static get type() {
    return "PlayerPoints";
  }

  prepare() {
    this.priority = 20;
  }

  render(ctx) {
    const player = this.value;
    if (player.resistance.tension >= 1) return;
    ctx.beginPath();
    for (let i = 0; i < player.points.length; ++i) {
      const p = player.points[i];
      if (!i) {
        ctx.moveTo(p.x, p.y);
        continue;
      }
      ctx.lineTo(p.x, p.y);
    }
    ctx.lineTo(player.x, player.y);
    ctx.lineCap = "round";
    ctx.strokeStyle = COLOR.background;
    ctx.lineWidth = 12;
    ctx.stroke();
    ctx.strokeStyle = COLOR.white;
    ctx.lineWidth = 4 * (1 - player.resistance.tension);
    ctx.stroke();
    ctx.lineWidth = 1;
  }
}

export class PlayerStatusLayer extends Layer {
  static get type() {
    return "PlayerStatus";
  }

  prepare() {
    this.priority = 21;
    this.healthP = 0;
    this.comboP = 0;
  }

  render(ctx) {
    const player = this.value;
    const healthP = Math.max(0, player.health / player.maxHealth);
    const comboP = Math.min(1, player.combo / 8);

    if (Math.abs(this.healthP - healthP) < 0.01) {
      this.healthP = healthP;
    } else {
      this.healthP += (healthP - this.healthP) * 0.05;
    }

    if (Math.abs(this.comboP - comboP) < 0.01) {
      this.comboP = comboP;
    } else {
      this.comboP += (comboP - this.comboP) * 0.05;
    }

    const x = MIN_X + 110;
    let y = MAX_Y - 96;
    let w = MAX_X - x - 16;
    let h = 36;
    const p = 1;
    let r = 10;

    ctx.lineWidth = 3;

    const faded = 0.6;
    const healthColor = "#f5222d";
    ctx.strokeStyle = healthColor;
    ctx.fillStyle = healthColor;

    // ctx.globalAlpha = faded;
    ctx.beginPath();
    ctx.roundRect(x - p, y - p, w + p * 2, h + p * 2, r + p);
    ctx.stroke();
    // ctx.fill();
    // ctx.globalAlpha = 1;

    ctx.beginPath();
    ctx.roundRect(x, y, w * this.healthP, h, 4);
    ctx.fill();

    y += h + p * 2 + 6;
    w *= 0.75;
    h *= 0.5;
    r *= 0.5;

    const comboColor = "#2f54eb";
    ctx.strokeStyle = comboColor;
    ctx.fillStyle = comboColor;

    // ctx.globalAlpha = faded;
    ctx.beginPath();
    ctx.roundRect(x - p, y - p, w + p * 2, h + p * 2, r + p);
    ctx.stroke();
    // ctx.fill();
    // ctx.globalAlpha = 1;

    ctx.beginPath();
    ctx.roundRect(x, y, w * this.comboP, h, 2);
    ctx.fill();

    ctx.lineWidth = 1;
  }
}

export class InkLayer extends Layer {
  static get type() {
    return "Ink";
  }

  prepare() {
    this.priority = 22;
  }

  render(ctx) {
    ctx.lineWidth = 6;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.strokeStyle = "#000";
    ctx.beginPath();
    const tremble = 1;
    let xTremble = 0;
    let yTremble = 0;
    for (const ink of this.values) {
      for (const p of ink.points) {
        if (!p.isConnected) {
          xTremble = range(-tremble, tremble);
          yTremble = range(-tremble, tremble);
        }
        const x = p.x + xTremble;
        const y = p.y + yTremble;
        if (p.isConnected) {
          ctx.lineTo(x, y);
        } else {
          ctx.moveTo(x, y);
        }
      }
    }
    ctx.stroke();
  }
}

export class BallLayer extends Layer {
  static get type() {
    return "Ball";
  }

  prepare() {
    this.priority = 60;
  }

  render(ctx) {
    ctx.beginPath();
    const [ball] = this.values;
    ctx.arc(ball.x, ball.y, BALL_R, 0, Math.PI * 2);
    ctx.fillStyle = "white";
    // ctx.strokeStyle = "white";
    // ctx.lineWidth = 3;
    // ctx.lineJoin = "round";
    ctx.fill();
    // ctx.stroke();
    // ctx.lineWidth = 1;
  }
}

const ElementColors = {
  normal: "white",
  slow: "#91caff",
  burn: "#ff7a45",
  sap: "#d3adf7",
  zap: "#fff566",
};

export class ProjectileLayer extends Layer {
  static get type() {
    return "Projectile";
  }

  prepare() {
    this.priority = 60;
  }

  render(ctx) {
    ctx.beginPath();
    for (const proj of this.values) {
      ctx.moveTo(proj.x, proj.y);
      const ratio = Math.min(1, proj.travelled / proj.range);
      let r = proj.r;
      if (ratio > 0.8) {
        r *= Math.sqrt(1 - 5 * (ratio - 0.8));
      }
      ctx.arc(proj.x, proj.y, r, 0, Math.PI * 2);
    }
    ctx.fillStyle = ElementColors[this.id.element] || "white";
    // ctx.strokeStyle = "white";
    // ctx.lineWidth = 3;
    // ctx.lineJoin = "round";
    ctx.fill();
    // ctx.stroke();
    // ctx.lineWidth = 1;
  }
}

export class NoteLayer extends Layer {
  static get type() {
    return "Note";
  }

  prepare() {
    this.priority = 55;
  }

  render(ctx) {
    ctx.beginPath();
    for (const note of this.values) {
      if (note.y < MIN_Y + 8) continue;
      ctx.moveTo(note.x, note.y);
      ctx.roundRect(note.x, note.y, note.w, note.h, 16);
    }
    ctx.fillStyle = COLOR.opponent;
    // ctx.strokeStyle = "white";
    // ctx.lineWidth = 3;
    // ctx.lineJoin = "round";
    ctx.fill();
    // ctx.stroke();
    // ctx.lineWidth = 1;

    const GLINT = 12;
    const GLINT_MARGIN = 6;
    ctx.beginPath();
    for (const note of this.values) {
      ctx.moveTo(note.x, note.y);
      ctx.roundRect(
        note.x + GLINT_MARGIN,
        note.y + note.h - GLINT - GLINT_MARGIN,
        (note.health / note.maxHealth) * (note.w - GLINT_MARGIN * 2),
        GLINT,
        16
      );
    }
    ctx.fillStyle = "rgba(0,0,0,0.1)";
    ctx.fill();
  }
}

export class NoteParticleLayer extends Layer {
  static get type() {
    return "NoteParticle";
  }

  prepare() {
    this.priority = 54;
  }

  render(ctx) {
    const now = Date.now();
    ctx.beginPath();

    for (const p of this.values) {
      const progress = Math.min(1, (now - p.startT) / p.duration);
      const t = Math.sin(Math.PI * progress * 0.5);
      ctx.moveTo(p.x3, p.y3);
      const x1 = p.x1 + t * (p.x3 - p.x1);
      const y1 = p.y1 + t * (p.y3 - p.y1);
      const x2 = p.x2 + t * (p.x3 - p.x2);
      const y2 = p.y2 + t * (p.y3 - p.y2);
      ctx.roundRect(x1, y1, x2 - x1, y2 - y1, 16);
      // ctx.lineTo(p.x2 + t * (p.x3 - p.x2), p.y2 + t * (p.y3 - p.y2));
    }

    // for (const p of this.values) {
    //   const progress = Math.min(1, (now - p.startT) / p.duration);
    //   if (progress < 0.5) continue;
    //   const t = Math.sin(Math.PI * (1 - progress));
    //   ctx.moveTo(p.x3, p.y3);
    //   ctx.arc(p.x3, p.y3, 16 * t, 0, Math.PI * 2);
    // }

    ctx.fillStyle = COLOR.opponent;
    ctx.fill();
  }
}

export class AbilityBorderLayer extends Layer {
  static get type() {
    return "AbilityBorder";
  }

  prepare() {
    this.priority = 50;
  }

  render(ctx) {
    ctx.beginPath();
    const now = Date.now();
    const mid = Math.PI * 3.5;
    for (const { x, y, r, lastUse, nextUse, isActive } of this.values) {
      let ready = 1;
      if (now < nextUse && nextUse - lastUse >= 1000) {
        ready = (now - lastUse) / (nextUse - lastUse);
      }

      // Adjust ready to scale the arc size
      // if (ready === 0) {
      //   ready = 0.999;
      // } else if (ready !== 1) {
      //   ready = 1 - ready;
      // }
      // const offset = Math.PI * ready;
      // const start = mid - offset;
      // const end = mid + offset;
      const start = mid + ready * Math.PI;
      const end = start + 2 * ready * Math.PI;
      ctx.moveTo(x + r * Math.cos(start), y + r * Math.sin(start));
      ctx.arc(x, y, r, start, end);
    }
    ctx.strokeStyle = "white";
    ctx.lineCap = "round";
    ctx.lineWidth = 3;
    ctx.stroke();
    ctx.lineWidth = 1;
  }
}

export class AbilityLogoLayer extends Layer {
  static get type() {
    return "AbilityLogo";
  }

  prepare() {
    this.priority = 50;
  }

  render(ctx) {
    ctx.beginPath();
    for (const { x, y, r, logo } of this.values) {
      for (const p of logo.projectiles) {
        ctx.moveTo(x + r * p.x, y + p.r * p.y);
        ctx.arc(x + r * p.x, y + r * p.y, r * p.r, 0, Math.PI * 2);
      }
    }
    ctx.fillStyle = "white";
    ctx.fill();
  }
}
